import { event } from '@zillow/universal-analytics';

var eventTypes = {
  RENT_VS_BUY_VIEW: 'rent vs buy view',
  RENT_VS_BUY_INTERACTION: 'rent vs buy interaction',
  RENT_AFFORDABILITY_VIEW: 'rent affordability view',
  RENT_AFFORDABILITY_INTERACTION: 'rent affordability interaction'
};
var rentAffordabilityInteractionTypes = {
  MONTHLY_NET_INCOME: 'monthly_net_income',
  MONTHLY_DEBTS: 'monthly_debts',
  MONTHLY_SAVINGS: 'monthly_savings',
  MONTHLY_EXPENSES: 'monthly_expenses',
  RENT_VALUE_SLIDER: 'rent_value_slider'
};
var clickstreamProperties = {
  envelope: {
    event_template_id: '91',
    event_template_version_id: '1',
    event_type_version_id: '1',
    event_type_id: ''
  },
  clickstream_trigger: {
    trigger_location_nm: '',
    trigger_type_nm: '',
    trigger_object_nm: '',
    trigger_source_nm: ''
  },
  semantic: {
    topic_tag_txt: ['calculator'],
    semantic_event_nm: ''
  },
  calc_block: {
    title_txt: '',
    displayed_fields: [],
    interaction_button_txt: ''
  }
};
function updateEnvelope(eventType) {
  var envelopeBlock = Object.assign({}, clickstreamProperties.envelope);
  if (eventType === eventTypes.RENT_VS_BUY_VIEW) {
    envelopeBlock.event_type_id = '480';
  } else if (eventType === eventTypes.RENT_VS_BUY_INTERACTION) {
    envelopeBlock.event_type_id = '481';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_VIEW) {
    envelopeBlock.event_type_id = '482';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_INTERACTION) {
    envelopeBlock.event_type_id = '483';
  }
  return envelopeBlock;
}
function updateClickstreamTrigger(eventType) {
  var triggerBlock = Object.assign({}, clickstreamProperties.clickstream_trigger);
  if (eventType === eventTypes.RENT_VS_BUY_VIEW) {
    triggerBlock.trigger_location_nm = 'rent_v_buy_calculator_page';
    triggerBlock.trigger_type_nm = 'view';
    triggerBlock.trigger_object_nm = 'no_trigger_object';
    triggerBlock.trigger_source_nm = 'rent_v_buy_calculator_page';
  } else if (eventType === eventTypes.RENT_VS_BUY_INTERACTION) {
    triggerBlock.trigger_location_nm = 'rent_v_buy_calculator_page';
    triggerBlock.trigger_type_nm = 'interaction';
    triggerBlock.trigger_object_nm = 'rent_v_buy_calculator_object';
    triggerBlock.trigger_source_nm = 'calculator_interaction_button';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_VIEW) {
    triggerBlock.trigger_location_nm = 'rent_affordability_calculator_page';
    triggerBlock.trigger_type_nm = 'view';
    triggerBlock.trigger_object_nm = 'no_trigger_object';
    triggerBlock.trigger_source_nm = 'rent_affordability_calculator_page';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_INTERACTION) {
    triggerBlock.trigger_location_nm = 'rent_affordability_calculator_page';
    triggerBlock.trigger_type_nm = 'interaction';
    triggerBlock.trigger_object_nm = 'rental_affordability_object';
    triggerBlock.trigger_source_nm = 'calculator_interaction_button';
  }
  return triggerBlock;
}
function updateSemantic(eventType) {
  var semanticBlock = Object.assign({}, clickstreamProperties.semantic);
  if (eventType === eventTypes.RENT_VS_BUY_VIEW) {
    semanticBlock.semantic_event_nm = 'view_content';
  } else if (eventType === eventTypes.RENT_VS_BUY_INTERACTION) {
    semanticBlock.semantic_event_nm = 'rent_v_buy_calculator_interaction';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_VIEW) {
    semanticBlock.semantic_event_nm = 'view_content';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_INTERACTION) {
    semanticBlock.semantic_event_nm = 'rent_affordability_calculator_interaction';
  }
  return semanticBlock;
}
function updateCalculatorBlock(eventType, interactionType, displayedFields) {
  if (displayedFields === void 0) {
    displayedFields = [];
  }
  var calcBlock = Object.assign({}, clickstreamProperties.calc_block);

  // Title
  if (eventType === eventTypes.RENT_VS_BUY_VIEW || eventType === eventTypes.RENT_VS_BUY_INTERACTION) {
    calcBlock.title_txt = 'rent_v_buy_calculator';
  } else if (eventType === eventTypes.RENT_AFFORDABILITY_VIEW || eventType === eventTypes.RENT_AFFORDABILITY_INTERACTION) {
    calcBlock.title_txt = 'rent_affordability_calculator';
  }
  calcBlock.displayed_fields = displayedFields;
  calcBlock.interaction_button_txt = interactionType || '';
  return calcBlock;
}
function getClickstreamProperties(eventType, interactionType, displayedFields) {
  if (displayedFields === void 0) {
    displayedFields = [];
  }
  return {
    envelope: updateEnvelope(eventType),
    clickstream_trigger: updateClickstreamTrigger(eventType),
    semantic: updateSemantic(eventType),
    calc_block: updateCalculatorBlock(eventType, interactionType, displayedFields)
  };
}
function handleRentAffordabilityClickstreamEvent(eventType, interactionType, displayedFields) {
  if (displayedFields === void 0) {
    displayedFields = [];
  }
  var payload = getClickstreamProperties(eventType, interactionType, displayedFields);
  event(payload);
}

export { eventTypes, handleRentAffordabilityClickstreamEvent, rentAffordabilityInteractionTypes };
