import { AgentSearchBlock, AmortizationBlock, CashOutRefinanceCalculatorBlock, ClosingCostCalculatorBlock, DownPaymentCalculatorBlock, DTICalculatorBlock, FHACalculatorBlock, HelocCalculatorBlock, HubspotFormBlock, LoanComparisonBlock, MortgageCalculatorBlock, MortgageRateTrendsBlock, MortgageRatesSummaryBlock, RentAffordabilityCalculatorBlock, RiddleEmbedBlock, VALoanCalculatorBlock } from '@zillow/zrs-cms';
import DtsCustomCta from '@zillow/custom-dts-cta';
import DtsPropertyBlock from '@zillow/custom-dts-block';
import ShowcaseDtcCustomCta from '@zillow/custom-dtc-cta';
import AffordabilityCalculator from '../blocks/AffordabilityCalculator/AffordabilityCalculatorBlock.js';
import AffordabilityCalculatorV2 from '../blocks/AffordabilityCalculatorV2/AffordabilityCalculatorV2.js';
import ApexField from '../blocks/ApexField.js';
import ApexFields from '../blocks/ApexFields.js';
import ApexForm from '../blocks/ApexForm.js';
import ApexSubmit from '../blocks/ApexSubmit.js';
import ApexSuccess from '../blocks/ApexSuccess.js';
import Button from '../blocks/Button/Button.js';
import ButtonGroup from '../blocks/ButtonGroup/ButtonGroup.js';
import Card from '../blocks/Card.js';
import Carousel from '../blocks/Carousel.js';
import CarouselV2 from '../blocks/Carousel/Carousel.js';
import Divider from '../blocks/Divider/Divider.js';
import Embed from '../blocks/Embed/Embed.js';
import FAQ from '../blocks/FAQ/FAQ.js';
import FAQItem from '../blocks/FAQItem.js';
import GridContent from '../blocks/GridContent.js';
import GridLayout from '../blocks/GridLayout.js';
import Group from '../blocks/Group/Group.js';
import Heading from '../blocks/Heading/Heading.js';
import HmimhwPropertySearch from '../blocks/HmimhwPropertySearch.js';
import Image from '../blocks/Image/Image.js';
import List from '../blocks/List/List.js';
import ListItem from '../blocks/ListItem.js';
import MediaObject from '../blocks/MediaObject/MediaObject.js';
import MediaStepper from '../blocks/MediaStepper/MediaStepperBlock.js';
import MilitaryInstallationHomeSearch from '../blocks/MilitaryInstallationHomeSearch/MilitaryInstallationHomeSearch.js';
import MilitaryRentCalculator from '../blocks/MilitaryRentCalculator/MilitaryRentCalculator.js';
import MortgageRatesTable from '../blocks/MortgageRatesTable/MortgageRatesTable.js';
import Paragraph from '../blocks/Paragraph/Paragraph.js';
import Picture from '../blocks/Picture/Picture.js';
import Posts from '../blocks/Posts/Posts.js';
import PropertySearch from '../blocks/PropertySearch/PropertySearch.js';
import PropertyTaxCalculator from '../blocks/PropertyTaxCalculator/PropertyTaxCalculator.js';
import Quote from '../blocks/Quote/Quote.js';
import RefinanceCalculator from '../blocks/RefinanceCalculator.js';
import SEOFooter from '../blocks/SEOFooter.js';
import SimonField from '../blocks/SimonField.js';
import SimonFields from '../blocks/SimonFields.js';
import SimonForm from '../blocks/SimonForm.js';
import SimonSuccess from '../blocks/SimonSuccess.js';
import Spacer from '../blocks/Spacer/Spacer.js';
import Table from '../blocks/Table.js';
import Tabs from '../blocks/Tabs/Tabs.js';
import TaxComparisonTable from '../blocks/TaxComparisonTable/TaxComparisonTable.js';
import Upsell from '../blocks/Upsell/Upsell.js';
import Video from '../blocks/Video/Video.js';
import Vimeo from '../blocks/Vimeo/Vimeo.js';
import WPBlock from '../blocks/WPBlock.js';
import WPList from '../blocks/WPList/index.js';
import Youtube from '../blocks/Youtube/Youtube.js';
import ZOAddressSearch from '../blocks/ZOAddressSearch.js';

// CMS Team

// !!! IMPORTANT !!!
// This is where you add your components to onboard with CMS
// Add your component block as an import above, then add it to the CMS_BLOCKS
// array below. If you need help, feel free to reach out to the CMS team
// For more information, reference the onboarding guide here: https://zillowgroup.atlassian.net/wiki/spaces/CMS/pages/798524524/CMS+Block+Onboarding
var CMS_BLOCKS = [AgentSearchBlock, AmortizationBlock, CashOutRefinanceCalculatorBlock, ClosingCostCalculatorBlock, DownPaymentCalculatorBlock, DTICalculatorBlock, DtsCustomCta, DtsPropertyBlock, FHACalculatorBlock, HelocCalculatorBlock, HubspotFormBlock, LoanComparisonBlock, MortgageCalculatorBlock, MortgageRateTrendsBlock, MortgageRatesSummaryBlock, RentAffordabilityCalculatorBlock, RiddleEmbedBlock, ShowcaseDtcCustomCta, VALoanCalculatorBlock, ClosingCostCalculatorBlock];
var BLOCK_MAPPING = Object.assign({
  'constellation/affordability-calculator': AffordabilityCalculator,
  'constellation/affordability-calculator-v2': AffordabilityCalculatorV2,
  'constellation/apex-field': ApexField,
  'constellation/apex-fields': ApexFields,
  'constellation/apex-form': ApexForm,
  'constellation/apex-submit': ApexSubmit,
  'constellation/apex-success': ApexSuccess,
  'constellation/button': Button,
  'constellation/button-group': ButtonGroup,
  'constellation/card': Card,
  'constellation/carousel': Carousel,
  'constellation/carousel-v2': CarouselV2,
  'constellation/divider': Divider,
  'constellation/embed': Embed,
  'constellation/faq': FAQ,
  'constellation/faq-item': FAQItem,
  'constellation/grid-content': GridContent,
  'constellation/grid-layout': GridLayout,
  'constellation/group': Group,
  'constellation/heading': Heading,
  'constellation/hmimhw-property-search': HmimhwPropertySearch,
  'constellation/image': Image,
  'constellation/list': List,
  'constellation/list-item': ListItem,
  'constellation/media-object': MediaObject,
  'constellation/media-stepper': MediaStepper,
  'constellation/military-installation-home-search': MilitaryInstallationHomeSearch,
  'constellation/military-rent-calculator': MilitaryRentCalculator,
  'constellation/mortgage-rates-table': MortgageRatesTable,
  'constellation/paragraph': Paragraph,
  'constellation/picture': Picture,
  'constellation/posts': Posts,
  // TODO: Posts can't be dynamic because of getBlocksData when we updated to next 13 we can fix this.
  'constellation/property-search': PropertySearch,
  'constellation/property-tax-calculator': PropertyTaxCalculator,
  'constellation/quote': Quote,
  'constellation/refinance-calculator': RefinanceCalculator,
  'constellation/seo-footer': SEOFooter,
  'constellation/simon-field': SimonField,
  'constellation/simon-fields': SimonFields,
  'constellation/simon-form': SimonForm,
  'constellation/simon-success': SimonSuccess,
  'constellation/spacer': Spacer,
  'constellation/table': Table,
  'constellation/tabs': Tabs,
  'constellation/tax-comparison-table': TaxComparisonTable,
  'constellation/upsell': Upsell,
  'constellation/video': Video,
  'constellation/vimeo': Vimeo,
  'constellation/youtube': Youtube,
  'constellation/zo-address-search': ZOAddressSearch,
  'core/block': WPBlock,
  'core/list': WPList
}, CMS_BLOCKS.reduce(function (obj, block, index) {
  var _block$metadata;
  if (!block) {
    throw new Error("No block found for index " + index);
  }
  if ((_block$metadata = block.metadata) !== null && _block$metadata !== void 0 && _block$metadata.name) {
    obj[block.metadata.name] = block;
  } else {
    console.error('No name found for block', block, 'Check if "name" is set in the block metadata');
  }
  return obj;
}, {}));

export { BLOCK_MAPPING };
